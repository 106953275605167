import React from "react";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import ThankYou from "../../../components/ConversionPath/demo-requested";
// import Image from '../../components/image';
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import { restaurantTestimonials } from "../../../data/restaurant-data";
import PrimaryCTA from "../../../components/Buttons/primary-cta";

export default function ThankYouPage() {
  const cta = (
    <PrimaryCTA
      ctaTitle="Download"
      target="/accelerating-profit-in-new-restaurants.pdf"
    />
  );

  return (
    <LpLayout>
      <SEO title="Thank you - your request for a gated asset has been received | SpotOn " />

      <ThankYou
        noPhoneMessage
        thankYouMessage="You can download your PDF here:"
        customCta={cta}
      />

      <TestmonialReviews sectionData={restaurantTestimonials} />
    </LpLayout>
  );
}
